
  import { login } from '../../api/account.js';
  import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
  import type { Rule } from 'ant-design-vue/es/form';
  import { defineComponent, reactive, ref } from 'vue';
  import type { FormInstance } from 'ant-design-vue';
  import { message } from 'ant-design-vue';
  import router from '../../router';
  interface FormState {
    username: string;
    password: string;
  }
  export default  defineComponent({
    // name: 'LoginContent',
    components: {
      UserOutlined,
      LockOutlined
    },
    setup() {
      const formRef = ref<FormInstance>();
      const formState = reactive<FormState>({
        username: '',
        password: '',
      });
      let validateUsername = async (_rule: Rule, value: string) => {
        if (value === '') {
          return Promise.reject('用户名不允许为空');
        } else {
          // if (/.*[\u4e00-\u9fa5]+.*$/.test(value)) {
          //   return Promise.reject('用户名不允许中文!')
          // }
          return Promise.resolve();
        }
      };
      let validatePassword = async (_rule: Rule, value: string) => {
        if (value === '') {
          return Promise.reject('密码不允许为空');
        } else if (/.*[\u4e00-\u9fa5]+.*$/.test(value)) {
          return Promise.reject("密码不允许中文!!");
        } else {
          return Promise.resolve();
        }
      };

      const rules: Record<string, Rule[]> = {
        username: [{ required: true, validator: validateUsername, trigger: 'change' }],
        password: [{ required: true, validator: validatePassword, trigger: 'change' }],
      };
      const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      };
      const handleFinish = (values: FormState) => {
        // console.log(values, formState);
        let data = new FormData()
        data.append('username', values.username)
        data.append('password', values.password)
        data.append('token', 'USER')
        login(data).then(res=>{
          // console.log(res)
          if(res.data.code!=200) {
            message.warn(res.data.message)
          }else {
            message.success('登陆成功')
            router.push({path:'/'})
            localStorage.setItem('userToken', res.data.token)
          }
        })
      };
      return {
        formState,
        formRef,
        rules,
        layout,
        handleFinish,
      };
    },
  });
