<template>
  <div class="headercontainer">
    <div class="headbar">
      <div class="headleft">
        <img src="../../assets/logo.jpg" width="100" height="60" />
        <a-divider type="vertical"/>
        <h2>WriteSomething</h2>
      </div>
      <div class="headright">
        <span @click="()=>{this.$router.push({path:'/'})}" >首页</span>
        <a-divider type="vertical" class="height: 10px;" />
        <a-popover title="帮助">
          <template v-slot:content>
            <span>有问题请联系937652213@qq.com</span>
          </template>
          <span>帮助</span>
        </a-popover>
      </div>
    </div>
    <div>
      <h1 style="font-size: 40px;">欢迎使用MyBlog</h1>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'AccountHeader',
    data () {
      return {
        
      }
    }
  }
</script>
<style scoped>
  .headercontainer{
    width: 100%;
    height: 200px;
  }
  .headbar {
    width: 100%;
    padding: 20px;
    height: 100px;
    justify-content: space-between;
    display: flex;
  }
  .headleft {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  .headright {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-right: 30px;
    font-size: 18px;
  }
  .ant-divider, .ant-divider-vertical {
    margin-top: 25px;
    height: 10px;
    font-size: 20;
    border-top: 20px solid black;
  }
</style>