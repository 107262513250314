import axios from 'axios';
import { URLS } from './allurl.js';

export function login(params){
  const url = URLS.ACCOUNT.LOGIN.url
  return axios({
    method: 'post',
    url: url,
    data: params,
  });
}
export function register(params) {
  const url = URLS.ACCOUNT.REGISTER.url
  return axios({
    method: 'post',
    url: url,
    data: params
  })
}

export function srhUserAttrInfoByUId() {
  return axios({
    method: 'post',
    url: URLS.ACCOUNT.USER_INFO_UId.url,
  })
}

export function getMailCode(params) {
  return axios({
    method: 'post',
    url: URLS.ACCOUNT.GET_MAIL_CODE.url,
    data: params
  })
}

export function resetPassword(params) {
  return axios({
    method: 'post',
    url: URLS.ACCOUNT.RST_PSWD.url,
    data: params
  })
}

export function uptUserInfo(params) {
  return axios({
    method: 'post',
    url: URLS.ACCOUNT.UPT_USER_INFO.url,
    data: params
  })
}

export function srhUserCBDataByUId(params) {
  return axios({
    method: 'post',
    url: URLS.ACCOUNT.SRH_USER_CB_DATA.url,
    data: params
  })
}

export function srhUserMsgs(params) {
  return axios({
    method: 'post',
    url: URLS.ACCOUNT.SRH_USR_MSGS.url,
    data: params
  })
}

export function delMsg(params) {
  return axios({
    method: 'post',
    url: URLS.ACCOUNT.DEL_MSG.url,
    data: params
  })
}

export function delMyCmt(params) {
  return axios({
    method: 'post',
    url: URLS.ACCOUNT.DEL_MY_CMT.url,
    data: params
  })
}

export function delMyMsg(params) {
  return axios({
    method: 'post',
    url: URLS.ACCOUNT.DEL_MY_MSG.url,
    data: params
  })
}

export function userUnReadMsgNum(params) {
  return axios({
    method: 'post',
    url: URLS.ACCOUNT.USR_UNREAD_MSG_NUM.url,
    data: params
  })
}

export function userMsgSignRead(params) {
  return axios({
    method: 'post',
    url: URLS.ACCOUNT.MSG_SIGN_READ.url,
    data: params
  })
}


export default { login, register, srhUserAttrInfoByUId, getMailCode, resetPassword, uptUserInfo,srhUserCBDataByUId,
  srhUserMsgs, delMsg, delMyCmt, delMyMsg, userUnReadMsgNum, userMsgSignRead }