
  import AccountHeader from './AccountHeader.vue';
  import LoginContent from './LoginContent.vue';
  import RegisterContent from './RegisterContent.vue';
  import { defineComponent, ref } from 'vue';
  export default defineComponent ({
    name: 'Login',
    components: {
      AccountHeader,
      LoginContent,
      RegisterContent
    },
    setup() {
      const activeKey = ref('1')
      let changeTab = (key:string) => {
        console.log('key', key)
        activeKey.value = key
      }
      return {
        activeKey,
        changeTab
      };
    },
  })
