
  import * as ACCOUNT from '../../api/account.js';
  import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons-vue';
  import type { Rule } from 'ant-design-vue/es/form';
  import { defineComponent, reactive, ref } from 'vue';
  import type { FormInstance } from 'ant-design-vue';
  import { message } from 'ant-design-vue';
  import router from '../../router';
  interface FormState {
    username: string;
    password: string;
    checkPassword: string;
    email: string;
  }
  export default defineComponent({
    name: 'RegisterContent',
    components: {
      UserOutlined,
      LockOutlined,
      MailOutlined
    },
    emits: {
      "changeTab": null,
    },
    setup(props, ctx) {
      const formRef = ref<FormInstance>();
      const formState = reactive<FormState>({
        username: '',
        password: '',
        checkPassword: '',
        email: ''
      });
      // end  
      let patrn=/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi;
      let validateUsername = async  (_rule: Rule, value: string) => {
        if (value=='') {
          return Promise.reject('用户名不允许为空')
        }else if(value.length<3 || value.length > 10) {
          return Promise.reject('用户名长度应在3到10个字符之间')
        }else {
          return Promise.resolve()
        }
      };
      // end
      let validatePassword = async  (_rule: Rule, value: string) => {
        if (value === '') {
          return Promise.reject('密码不允许为空')
        } else {
          if (value.length<6 || value.length > 16) {
            return Promise.reject('密码长度应在6到16个字符之间!')
          }else if (patrn.exec(value)) {
            return Promise.reject('密码不能含有中文及全角符号!')
          }else if (formState.checkPassword !== '') {
            formRef!.value!.validateFields('checkPassword');
          }
          return Promise.resolve()
        }
      };
      let validateCheckPassword = async  (_rule: Rule, value: string) => {
        if (value === '') {
          return Promise.reject('校验密码不允许为空')
        } else if (value !== formState.password) {
          return Promise.reject("两次输入的密码不一致")
        } else {
          return Promise.resolve()
        }
      };
      let checkEmail = (_rule: Rule, value: string) => {
        let reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
        if (value === '') {
          return Promise.reject('邮箱不能为空')
        }else if (!reg.test(value)) {
          return Promise.reject('邮箱格式不正确')
        }else {
          return Promise.resolve()
        }
      };
      const rules: Record<string, Rule[]> = {
        username: [{ required: true, validator: validateUsername, trigger: 'change' }],
        password: [{ required: true, validator: validatePassword, trigger: 'change' }],
        checkPassword: [{ required: true, validator: validateCheckPassword, trigger: 'change' }],
        email: [{ required: true, validator: checkEmail, trigger: 'change' }]
      };
      const handleFinish = (values: FormState) => {
        // console.log(values, formState);
        let data = new FormData()
        data.append('username', values.username)
        data.append('password', values.password)
        data.append('email', values.email)
        // ctx.emit('changeTab', '1')
        ACCOUNT.register(data).then(res=>{
          if (res.data.data === 201) {
            message.warn('用户名已注册')
          }else {
            message.success('注册成功,请登录')
            ctx.emit('changeTab', '1')
          }
        })
      };
      const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      };
      return {
        formState,
        formRef,
        rules,
        layout,
        handleFinish,
      };
    }
  }) 
