import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_account_header = _resolveComponent("account-header")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_login_content = _resolveComponent("login-content")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_register_content = _resolveComponent("register-content")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { class: "container" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_header, { style: {"height":"200px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_account_header)
        ]),
        _: 1
      }),
      _createVNode(_component_a_layout_content, { class: "content" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tabs, {
            type: "card",
            activeKey: _ctx.activeKey,
            "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_tab_pane, {
                key: "1",
                tab: "登陆"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_login_content)
                ]),
                _: 1
              }),
              _createVNode(_component_a_tab_pane, {
                key: "2",
                tab: "注册"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_register_content, { onChangeTab: _ctx.changeTab }, null, 8, ["onChangeTab"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["activeKey"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}